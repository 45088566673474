import React from 'react'
import { Link } from 'gatsby'
import sample from 'lodash/sample'

import { Layout, Typing } from '../components'

const LinkToBlog = () => (
  <Link to='/blog/'><Typing>Check out my blog.</Typing></Link>
)

const LinkToProjects = () => (
  <Link to='/projects/'><Typing>Check out my projects.</Typing></Link>
)

const links = [
  <LinkToBlog />,
  <LinkToProjects />,
];

export default () => (
  <Layout>
    <div>
      <h1><Typing>Hi I'm Seth</Typing></h1>
      <p>Welcome to my humble corner of the web.</p>
      {sample(links)}
    </div>
  </Layout>
)
